import React from 'react';
import './Dropdown.style.scss';

interface DropdownMenuProps {
  isOpen: boolean;
  children: React.ReactNode;
}

const DropdownMenu = ({ isOpen, children }: DropdownMenuProps) => {
  return (
    <div className={`dropdown__menu ${isOpen ? 'dropdown__menu--open' : ''}`}>
      {isOpen && children}
    </div>
  );
};

export default DropdownMenu;
