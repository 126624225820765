import React from 'react';
import './Button.style.scss';

interface ButtonProps {
  type: 'primary' | 'secondary' | 'tertiary';
  label: string;
  onClick: () => void;
}

const Button = ({ type, label, onClick }: ButtonProps) => {
  return (
    <button className={`btn btn-${type}`} onClick={onClick}>
      <span className="text-md-md">{label}</span>
    </button>
  );
};

export default Button;
