import React from 'react';
import Header from '../../components/header/Header';

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="h1">Home</div>
    </React.Fragment>
  );
};

export default Home;
