import './styles/global.scss';
import NavBar from './components/navigation/NavBar';
import { Outlet } from 'react-router-dom';
import React from 'react';

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="page">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
