import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../Auth/Login';
import NavItem from './NavItem';
import Logo from '../../assets/images/forkandfriends.png';
import './NavBar.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../common/Dropdown/Dropdown';
import DropdownItem from './../common/Dropdown/DropdownItem';
import {
  Create,
  Diversity1Outlined,
  MenuBookOutlined,
  PersonOutline,
} from '@mui/icons-material';
import Avatar from '../common/Avatar/Avatar';
import LogoutButton from '../Auth/Logout';

const NavBar = () => {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <nav className="nav shadow-lg">
      <img
        className="nav__logo"
        src={Logo}
        alt="Fork and Friends"
        onClick={navigateToHome}
      />
      <ul className="nav__list">
        <NavItem to="/recipes">Recipes</NavItem>
        <NavItem to="/WeekMenu">Week Menu</NavItem>
        <NavItem to="/WeekMenu">About Us</NavItem>
      </ul>
      {isAuthenticated ? (
        <Dropdown
          trigger={
            <img
              src={user?.picture}
              alt="User Avatar"
              className="user-avatar"
            />
          }
        >
          <div className="dropdown__avatar">
            <Avatar
              userName={user?.name}
              userEmail={user?.email}
              avatar={user?.picture}
            />
          </div>
          <div className="divider"></div>
          <DropdownItem to="/profile" label="View Profile">
            <PersonOutline />
          </DropdownItem>
          <DropdownItem to="/friends" label="Friends">
            <Diversity1Outlined />
          </DropdownItem>
          <DropdownItem to="/settings" label="Settings">
            <PersonOutline />
          </DropdownItem>
          <div className="divider"></div>
          <DropdownItem to="/user/recipes" label="Own Recipes">
            <MenuBookOutlined />
          </DropdownItem>
          <DropdownItem to="/user/recipes/create" label="New Recipe">
            <Create />
          </DropdownItem>
          <div className="divider"></div>
          <LogoutButton />
        </Dropdown>
      ) : (
        <LoginButton />
      )}

      {/* {isAuthenticated ? (
        <NavDropdownAvatar
          userName={user?.name}
          userEmail={user?.email}
          avatar={user?.picture}
        >
          <NavDropdownItem to="/profile" label="View Profile">
            <PersonOutline />
          </NavDropdownItem>
          <NavDropdownItem to="/test2" label="Friends">
            <Diversity1Outlined />
          </NavDropdownItem>
          <NavDropdownItem to="/test3" label="Settings">
            <SettingsOutlined />
          </NavDropdownItem>
          <div className="divider"></div>
          <LogoutButton />
        </NavDropdownAvatar>
      ) : (
        <LoginButton />
      )} */}
    </nav>
  );
};

export default NavBar;
