import React from 'react';
import './RecipeCard.styles.scss';
import { Comment, Favorite, Star } from '@mui/icons-material';
import Avatar from '../common/Avatar/Avatar';

interface RecipeCardProps {
  key: React.Key;
  recipeImage: string;
  title: string;
  description: string;
  tags?: string[];
  favorites: number;
  comments: number;
  rating: number;
  authorName: string;
  authorAvatar: string;
}

const RecipeCard = ({
  key,
  recipeImage,
  title,
  description,
  tags,
  favorites,
  comments,
  rating,
  authorName,
  authorAvatar,
}: RecipeCardProps) => {
  return (
    <div className="recipe-card shadow-lg" key={key}>
      <div className="recipe-card__header">
        <img
          src={recipeImage}
          alt={`Image of ${title}`}
          className="recipe-card__header-image"
        />
        <div className="recipe-card__tags">
          {tags?.map((tag) => (
            <span className="tag text-xs-rg" key={tag}>
              {tag}
            </span>
          ))}
        </div>
      </div>
      <div className="recipe-card__details">
        <h3>{title}</h3>
        <p className="recipe-card__description text-md-rg">{description}</p>
        <div className="recipe-card__meta">
          <span className="recipe-card__meta-item">
            <Favorite /> {formatNumber(favorites)}
          </span>
          <span className="recipe-card__meta-item">
            <Comment /> {formatNumber(comments)}
          </span>
          <span className="recipe-card__meta-item">
            <Star /> {rating}
          </span>
        </div>
        <Avatar userName={authorName} avatar={authorAvatar} />
      </div>
    </div>
  );
};

function formatNumber(num: number): string {
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num.toString();
}

export default RecipeCard;
