import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import Button from '../common/Button/Button';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button type="primary" label="Log In" onClick={() => loginWithRedirect()} />
  );
};

export default LoginButton;
