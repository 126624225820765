import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { LogoutOutlined } from '@mui/icons-material';
import DropdownItem from '../common/Dropdown/DropdownItem';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <DropdownItem to="" label="Log Out" onClick={logout}>
      <LogoutOutlined />
    </DropdownItem>
  );
};

export default LogoutButton;
