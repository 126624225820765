import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavItem.scss';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavItem: React.FC<NavLinkProps> = ({ to, children }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `text-md-md nav-item ${isActive ? 'nav-item--active' : ''}`
    }
  >
    {children}
  </NavLink>
);

export default NavItem;
