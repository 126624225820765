import React from 'react';
import Header from '../../components/header/Header';
import RecipeCard from '../../components/RecipeCard/RecipeCard';
import './Recipes.styles.scss';
import MockData from '../../assets/recipe-mock.json';

const RecipesPage = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="recipe__wrapper">
        {MockData.map((recipe) => (
          <RecipeCard
            key={recipe.Id}
            recipeImage={recipe.image}
            title={recipe.title}
            description={recipe.description}
            favorites={recipe.metadata.likes}
            comments={recipe.metadata.comments}
            rating={recipe.metadata.rating}
            authorName={recipe.author.username}
            authorAvatar={recipe.author.avatarImage}
          />
        ))}
      </section>
    </React.Fragment>
  );
};

export default RecipesPage;
