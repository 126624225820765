import { NavLink } from 'react-router-dom';
import React from 'react';
import './Dropdown.style.scss';

interface DropdownItemProps {
  children?: React.ReactNode;
  label: string;
  to: string;
  onClick?: () => void;
}

const DropdownItem = ({ children, label, to, onClick }: DropdownItemProps) => {
  return (
    <NavLink
      to={to}
      className="dropdown__item text-sm-md"
      {...(onClick ? { onClick: onClick } : {})}
    >
      <span className="dropdown__icon">{children}</span>
      {label}
    </NavLink>
  );
};

export default DropdownItem;
