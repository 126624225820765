import React from 'react';
import './Avatar.style.scss';

interface AvatarProps {
  userName?: string;
  userEmail?: string;
  avatar?: string;
}

const Avatar = ({ userName, userEmail, avatar }: AvatarProps) => {
  return (
    <div className="avatar">
      <img
        src={avatar}
        alt="User Avatar"
        className="avatar__image"
        referrerPolicy="no-referrer"
      />
      <div className="avatar__details">
        <p className="avatar__name text-sm-sb text-color-primary">
          {userName ?? 'Unknown User'}
        </p>
        <p className="avatar__email text-xs-rg text-color-secondary">
          {userEmail ?? ''}
        </p>
      </div>
    </div>
  );
};

export default Avatar;
