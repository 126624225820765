import React, { useState } from 'react';
import DropdownTrigger from './DropdownTrigger';
import DropdownMenu from './DropdownMenu';
import './Dropdown.style.scss';

interface DropdownProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
}

const Dropdown = ({ trigger, children }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="dropdown">
      <DropdownTrigger isOpen={isOpen} toggleDropdown={toggleDropdown}>
        {trigger}
      </DropdownTrigger>
      <DropdownMenu isOpen={isOpen}>{children}</DropdownMenu>
    </div>
  );
};

export default Dropdown;
