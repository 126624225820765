import React from 'react';
import './Header.scss';

interface HeaderProps {
  children?: React.ReactNode;
}

const Header = ({ children }: HeaderProps) => {
  return (
    <div className="header">
      <div className="header__title">
        <h1 className="h-white">
          Discover, Create and Share Delicious Recipes
        </h1>
        <h2 className="h-white">Your Weekly Menu Awaits!</h2>
      </div>
      {children}
    </div>
  );
};

export default Header;
