import React from 'react';
import './Dropdown.style.scss';

interface DropdownTriggerProps {
  isOpen: boolean;
  toggleDropdown: () => void;
  children: React.ReactNode;
}

const DropdownTrigger = ({
  isOpen,
  toggleDropdown,
  children,
}: DropdownTriggerProps) => {
  return (
    <div
      className="dropdown__trigger"
      onClick={toggleDropdown}
      aria-expanded={isOpen}
    >
      {children}
    </div>
  );
};

export default DropdownTrigger;
